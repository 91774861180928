/* eslint-disable @typescript-eslint/no-unused-vars*/
import { ComponentPublicInstance } from "vue";
import {
  RouteRecordRaw,
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteLocationRaw,
} from "vue-router";
import Home from "../components/HomePage.vue";
import auth from "../app/auth";
import LogoutSuccess from "../components/LogoutSuccess.vue";
import UserInfoStore from "../app/user-info-store";
import UserInfoApi from "../app/user-info-api";
import ErrorComponent from "../components/ErrorPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomePage",
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter(_to, _from, _next): void {
      auth.auth.getSession();
    },
    component: Home,
  },
  {
    path: "/login/oauth2/code/cognito",
    name: "Cognito",
    beforeEnter(_to, _from, _next): void {
      const currUrl = window.location.href;
      auth.auth.parseCognitoWebResponse(currUrl);
    },
    component: Home,
  },
  {
    path: "/logout",
    component: LogoutSuccess,
    beforeEnter(_to, _from, next): void {
      auth.logout();
      next();
    },
  },
  {
    path: "/error",
    component: ErrorComponent,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

/* eslint-disable @typescript-eslint/no-explicit-any */
type NavigationGuardNextCallback = (vm: ComponentPublicInstance) => any;

type NavigationGuardReturn =
  | void
  | Error
  | RouteLocationRaw
  | boolean
  | NavigationGuardNextCallback;

function requireAuth(
  to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
): NavigationGuardReturn | Promise<NavigationGuardReturn> {
  if (!auth.auth.isUserSignedIn()) {
    UserInfoStore.setLoggedIn(false);
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    UserInfoApi.getUserInfo().then((response) => {
      UserInfoStore.setLoggedIn(true);
      UserInfoStore.setCognitoInfo(response);
      next();
    });
  }
}
